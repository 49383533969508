import React, { useState } from 'react';



const navBtns = [{ ref: '/#contact', name: "Contact" }]
const NavBtn = ({ active, text, href, id, ...props }: { active?: boolean, text: string, href: string, id: string }) => {
  return <a
    href={href}
    key={id}
    className={`py-4 px-2 font-semibold ${active ? "text-softblue border-b-4 border-softblue" : "text-light hover:text-accent transition duration-300"}`}
  >
    {text}
  </a>
}

const NavBtnMobile = ({ active, text, href, ...props }: { active?: boolean, text: string, href: string, }) => {
  return <a
    href={href}
    className={`block text-sm px-2 py-4 text-white ${active ? " bg-softblue font-semibold" : "hover:bg-softblue transition duration-300"}`}
  >
    {text}
  </a>
}

const NavBar = () => {
  const [showMobileList, setShowMobileList] = useState(false);
  //const location = useLocation();
  function handleBurgerClicked() {
    setShowMobileList(!showMobileList);
  }

  // sticky top-0 z-20  bg-gradient-to-r from-softblue to-darkblue 
  return (
    <nav style={{transform: 'translate3d(0,0,0) translateZ(1002px)'}} className="absolute top-0 w-full z-40">
      <div className="container mx-auto py-4 px-8">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              <a href="/#" className="flex items-center py-4 px-2">
                <span className="font-semibold text-light uppercase text-lg">Epione</span>
              </a>
            </div>

          </div>
          <div className="hidden md:flex items-center space-x-2 ">
            <div className="hidden md:flex items-center space-x-1">
              {navBtns.map((btn, index) => {
                return <NavBtn text={btn.name} key={`nav-btn-wrap-${index}`} id={`nav-btn-web-${index}`} href={btn.ref} active={false} />
              })}
            </div>
            {/* <a
              href=""
              className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
            >
              Log In
            </a>
            <a
              href=""
              className="py-2 px-2 font-medium text-white bg-green-500 rounded hover:bg-green-400 transition duration-300"
            >
              Sign Up
            </a> */}
          </div>
          <div className="md:hidden flex items-center">
            <button onClick={handleBurgerClicked} className="outline-none mobile-menu-button">
              <svg
                className=" w-6 h-6 text-white hover:text-softblue "
                // x-show="!showMenu"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${showMobileList ? '' : 'hidden'} mobile-menu`}>
        <ul className="">
          {navBtns.map((btn, id) => {
            const activeBtn = false //location.pathname == btn.ref;
            return <li className={activeBtn ? 'active' : undefined} key={id}><NavBtnMobile text={btn.name} href={btn.ref} active={activeBtn} /></li>
          })}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
