import React, {useState} from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

const NavB = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  }
  return <><header className="lg:px-16 px-6 flex flex-wrap items-center lg:py-0 py-2">
  <div className="flex-1 flex justify-between items-center">
    <a href="#" className='uppercase'>
      Epione
  </a>
</div>

<a onClick={toggleHamburger} for="menu-toggle" className="cursor-pointer lg:hidden block"><svg className="fill-current text-gray-900" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path></svg></a>



<div className="hidden lg:flex lg:items-center lg:w-auto w-full" id="menu">
  <nav>
    <ul className="lg:flex items-center justify-between text-base text-gray-700 pt-4 lg:pt-0">
      <li><a className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400" href="#">Features</a></li>
      <li><a className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400" href="#">Pricing</a></li>
      <li><a className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400" href="#">Documentation</a></li>
      <li><a className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2" href="#">Support</a></li>
    </ul>
  </nav>
</div>
</header>
<div className={`${hamburgerOpen? "": "hidden"} `}>Mobile menu</div>
</>
}
const Layout = ({ children }) => (
  
    <div className="flex flex-col justify-center sm:max-w-full  bg-light">
      <NavBar />
      {/* container mx-auto lg:max-w-screen-xl <NavB></NavB> */}
      <main className="text-light z-0">{children}</main>
      <Footer />
    </div>
  
  
);

export default Layout;
